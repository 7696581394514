import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Note = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Note;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/note.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Note"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/notifications-note--docs"
      />
      <CodeSnippet
        scope={{ React: React, Note: Note }}
        code={snippet}
        platform="react"
        gitHubLink="notifications/note"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>The text that appears in the note.</Text>
          </PropListItem>

          <ClassnamePartial componentName="note" />

          <EnvironmentPartial />

          <PropListItem name="size" types={['string']}>
            <Text>Determines the size of note.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code>
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="type" types={['string']}>
            <Text>Determines the type of note.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="note" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Notifications / Note"
      />
    </PlatformTab>
  );
};

export default WebTab;
